$font-family-heading: 'Nunito', sans-serif;
$font-family-base: 'Nunito', sans-serif;
$font-size-base: .813rem;


$theme-colors: (
    "primary": #4F2D7F,
    "light": #fff,
    "dark": #000,
    "danger": #de002e,
    "soft-grey": #cbc4bc,
);

//colors
$dark-color: #000;
$soft-grey-color: #cbc4bc;

//table font size
$font-table: 14px;

//font-sizes
$font-sizes: (
    "font-14": 14px,
    "font-16": 16px,
    "font-18": 18px,
    "font-32": 32px,
);

//values
$per-50: 50%;
$per-100: 100%;

$value-8: 8px;
$value-10: 10px;
$value-20: 20px;
$value-15: 15px;
$value-32: 32px;
$value-45: 45px;
$value-55: 55px;

//display
$displays: (
    "block": block,
    "inline-block": inline-block,
    "flex": flex,
    "inline-flex": inline-flex,
);


// LAYOUT VARS
$topbar-height: 80px;
$topbar-height-mobile: 70px;

$sidebar-left-width: 120px;
$sidebar-left-width-mobile: 90px;
$sidebar-left-secondary-width: 220px;
$sidebar-left-secondary-width-mobile: 190px;
$side-clearance: 20px;

// GLOBAL COLORS
$facebook: rgb(55, 101, 201);
$google: rgb(236, 65, 44);
$twitter: rgb(3, 159, 245);
$instagram:rgb(193,53,132);
$linkedin:rgb(0,119,181);
$dribble:#ea4c89;
$youtube: #c4302b;
