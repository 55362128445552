@font-face {
    font-family: 'GT-Walsheim-Pro-Regular';
    src: url('/customer-assets/fonts/GT-Walsheim-Pro-Regular.ttf') format('truetype');
}
@font-face {
    font-family: 'GT-Walsheim-Pro-Medium';
    src: url('/customer-assets/fonts/GT-Walsheim-Pro-Medium.ttf') format('truetype');
}
@font-face {
    font-family: 'GT-Walsheim-Pro-Bold';
    src: url('/customer-assets/fonts/GT-Walsheim-Pro-Bold.ttf') format('truetype');
}

body {
    font-family: 'GT-Walsheim-Pro-Regular';
    color: map-get($theme-colors , dark );
    font-size: map-get($font-sizes , font-14 );
}

select.form-control {
    -webkit-appearance: auto;
}
.btn-secondary {
    &:hover {
        background-color: map-get($theme-colors , danger ) !important;
        border-color: map-get($theme-colors , danger );
        color: map-get($theme-colors, light) !important;
    }
}

.input_group_box {
    [type=text].form-control {
        height: 42px;
    }
    .input-group-append {
        .btn-danger {
            border-color: map-get($theme-colors , danger );
        }
    }
}


.login_card {
    border-radius: 20px;
}
.form-group label {
    font-size: 14px;
}
.login_btn {
    text-transform: uppercase;
}
.auth-logo img {
    width: 250px;
    height: auto;
}
.auth-layout-wrap {
    .auth-content {
        min-width: 500px;
    }
}
.auth-content {
    form {
        .form-group {
            .form-control {
                height: 40px;
                border-radius: 100px;
                padding-left: 1rem;
                padding-right: 1rem;
                border-radius: 3px;
            }
        }
        .btn-primary {
            font-size: 1rem;
            padding-top: 0.6rem;
            padding-bottom: 0.6rem;
        }
    }
}

.logo-new {
    width: 220px;
    padding-left: 20px;
}
.app-footer {
    .footer-bottom {
        .logo {
            width: 10rem;
        }
        .footerbox {
            width: 100%;
        }
    }
}

.table-bordered {
    thead {
        tr {
            th {
                background-color: rgba(0, 0, 0, 0.05);
            }
        }
    }
}

.card {
    .card-header {
        color: #000;
    }
}


/*login page*/
.auth-layout-wrap {
    justify-content: flex-start;
    align-items: flex-end;
    .auth-content {
        width: 33.33%;
        position: relative;
        min-width: auto;
        max-width: inherit;
        margin: inherit;

    }
    .auth-content>.card {
        border-radius: 0;
        height: 100vh;
        width: 100%;
        position: absolute;
        top: 0;
        right: 0;
        border-top: 5px solid #4F2D7F;
    }
}

.login_right_bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 9;
}
.login_foot_social {
    margin: 0;
    padding: 0.5rem 1.5rem;
    list-style-type: none;
    li {
        display: inline-block;
        margin-right: 10px;
        img {
            max-width: 100%;
            height: 30px;
        }
    }
}
.login_footer_rights {
    background-color: map-get($theme-colors , primary );
    padding: 0.5rem 1.5rem;
    color: #fff;
    margin: 0;
    font-size: 11px;
    line-height: normal;
    p {
        margin: 0;
    }
}
/*login page*/

/*customer dashboard*/
.card {
    border-radius: 0;
}
.table {
    thead {
        th {
            background-color: map-get($theme-colors, light);
            color: rgba($dark-color, 0.6);
            font-size: $font-table;
            border-top: none;
        }
    }
    tbody {
        tr {
            td {
                font-size: $font-table;
                color: map-get($theme-colors , dark );
            }
            &:hover {
                box-shadow: 0 0 2.5rem 0 rgb(0 0 0 / 15%);
            }
        }
    }
    &.dataTable {
        border-collapse: collapse !important;
    }
}

div.dataTables_wrapper div.dataTables_filter label {
    margin: 0;
}
.dataTables_filter .form-control {
    height: 2.5rem;
}
.form-control {
    border-radius: 0;
    display: map-get($displays , block );
    width: $per-100;
    height: 30px;
    font-size: map-get($font-sizes , font-14 );
    font-weight: 400;
    line-height: 2rem;
    border: 1px solid rgba($dark-color, 0.6);
    background-color: map-get($theme-colors , light );
    color: map-get($theme-colors , dark );
    border-radius: 0;
    transition: color 0.2s ease, background-color 0.2s ease, border-color 0.2s cubic-bezier(0.73, 0, 0.32, 0.96);
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}
div.dataTables_wrapper div.dataTables_length select {
    width: $value-45 !important;
    padding: 0 6px;
    text-align: center;
    height: 2.5rem;
}
div.dataTables_wrapper div.dataTables_filter label,
div.dataTables_wrapper div.dataTables_length label {
    font-size: map-get($font-sizes , font-16 );
}
div.dataTables_wrapper div.dataTables_info {
    color: map-get($theme-colors , dark );
    font-size: map-get($font-sizes , font-16 );
}

.pagination .page-link {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 1.75rem;
    height: 1.875rem;
    color: #4f2d7f;
    text-align: center;
    border: 1px solid transparent;
    border-radius: 0 !important;
}
.breadcrumb {
    margin-bottom: $value-32;
    h1 {
        color: map-get($theme-colors , dark );
        font-size: map-get($font-sizes , font-32 );
        margin-right: $value-10;
    }
    ul {
        li {
            color: map-get($theme-colors , dark );
            a {
                color: rgba($dark-color, 0.6);
                &:hover {
                    color: #de002e;
                }
            }
            &:after {
                content: "\f105";
                font-family: 'Line Awesome Free';
                font-weight: 900;
                width: 0;
                height: 0;
                font-size: map-get($font-sizes, font-14);
                color: map-get($theme-colors, dark);
                background: none;
                top: 0;
                right: 6px;
            }
        }
    }
}
.app-footer {
    background: rgba($soft-grey-color, 0.2);
}
.card-title {
    font-size: map-get($font-sizes , font-18 );
    border-bottom: 1px solid rgba($soft-grey-color, 0.6);
    padding-bottom: $value-15;
}
.btn {
    border-radius: 0;
    font-size: map-get($font-sizes , font-14 );
    padding: $value-8 $value-20;
    &:hover,
    &:focus {
        box-shadow: none;
    }
}
.btn-success {
    color: map-get($theme-colors , light );
    &:hover {
        color: map-get($theme-colors , light );
    }
}
.btn-primary {
    &:hover {
        background: map-get($theme-colors , danger );
        border-color: map-get($theme-colors , danger );
    }
}
.input-group-append {
    .btn {
        height: auto !important;
    }
}
.nav-tabs {
    .nav-item {
        .nav-link {
            font-size: map-get($font-sizes , font-14 );
        }
    }
}
.card {
    border: 1px solid rgba(0, 0, 0, 0.125);
    box-shadow: 0 2px 10px rgb(0 0 0 / 5%);
    // border-radius: 5px;
    .card-header {
        font-size: map-get($font-sizes , font-18 );
    }
}
.card.user-profile {
    border-color: transparent;
}

.layout-sidebar-large .main-header {
    box-shadow: none;
    border-bottom: 1px solid rgba($soft-grey-color, 0.6);
}
.header-part-right {
    .dropdown {
        .dropdown-header {
            font-size: map-get($font-sizes , font-14 );
            color: map-get($theme-colors , dark );
        }
        .dropdown-item {
            font-size: map-get($font-sizes , font-14 );
            color: map-get($theme-colors , primary );
            &:hover {
                color: map-get($theme-colors , danger );
                background-color: map-get($theme-colors , soft-grey );
            }
        }
    }
    .user img {
        border: 1px solid rgba($soft-grey-color, 0.2);
    }
}

.dataTables_empty {
    color: map-get($theme-colors , danger ) !important;
}

.web_details_boxes {
    padding: $value-20;
    background-color: rgba($soft-grey-color, 0.2);
}
.layout-sidebar-large .sidebar-left-secondary .childNav li.nav-item a:hover {
    background: rgba($soft-grey-color, 0.2);
}
.sidebar-left-secondary .childNav li.nav-item.open>a {
    background: rgba($soft-grey-color, 0.2);
}
.main-content-wrap {
    background: #f9f9f9;
}
.docs_card_box .btn {
    word-break: break-all;
}

#about.tab-pane {
    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    strong,
    span {
        font-family: "GT-Walsheim-Pro-Regular" !important;
    }
}

#addPoll {
    .modal-body {
        max-height: 390px;
        overflow: auto;
    }
}
/*customer dashboard*/

/*dashboard*/
.nav-item {
    img {
        height: 32px;
    }
}
.childNav {
    .nav-item {
        .item-name {
            margin-left: 5px;
        }
    }
}

.dash_main_card_block {
    .card-icon-bg {
        .card-body {
            .content {
                max-width: inherit;
                p {
                    color: map-get($theme-colors , dark );
                }
            }
            i {
                color: map-get($theme-colors , primary );
                font-size: 3rem;
            }
        }
    }
}
.input-group-append {
    .btn {
        border: 1px solid;
    }
}
/*dashboard*/


.docs_card_box {
    text-align: center;
    img {
        height: 160px;
        object-fit: cover;
    }
    label {
        float: left;
        margin: 0;
    }
    .card-header {
        padding-left: 10px;
        padding-right: 10px;
    }
    .card-footer {
        padding-left: 10px;
        padding-right: 10px;
    }
    .card-body {
        label {
            margin: 0;
        }
    }
}

.gtmeet_info_box{
    display: flex;
    align-items: center;
    justify-content: space-between;

    .share{
        border: 1px solid #4f2d7f;
        padding: 3px 10px 1px 10px;
        border-radius: 6px;
        background-color: rgb(123 31 162/9%);
        color: #4f2d7f;
    }
}
.radio_btn{

    &.padding_none{
        padding-top: 0;
    }

    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-top: 30px;

    .form-group{
        border: 1px solid #c9c9c9;
        padding: 2px 20px 5px 20px;
        border-radius: 5px;
        cursor: pointer;
        margin-bottom: 0;

        &.form-group label{
        margin-bottom: 0;
        padding-bottom: 0;
        }

        &.col-form-label{
            padding-bottom: 0;
        }
    }
}





/*responsive*/
@media (max-width:767px) {
    .auth-layout-wrap {
        .auth-content {
            width: 100%;
        }
    }
    .auth-content>.card {
        border-left: 5px solid #4F2D7F;
        border-top: none !important;
    }
}

@media (min-width:768px) and (max-width:991px) {
    .auth-layout-wrap {
        .auth-content {
            width: 50%;
        }
    }
}

@media (min-width:992px) and (max-width:1199px) {
    .auth-layout-wrap {
        .auth-content {
            width: 40%;
        }
    }
}

@media (min-width:1400px) {
    .table tbody tr td {
        font-size: 16px;
    }
    .btn {
        font-size: map-get($font-sizes, font-16);
    }
}
